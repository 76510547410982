import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import "tailwindcss/tailwind.css";
import AddTaskModal from "./AddTaskModal";
import axiosInstance from "../api/Axios"; // Import the axiosInstance
import TaskCard from "./TaskCard"; // Import the TaskCard component
import axios from "axios"; // Import axios for fetching funny text

// Reorder tasks within the same category
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list); // Ensure immutability
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// Move tasks between different categories
const move = (sourceTasks, destinationTasks, source, destination) => {
  const sourceClone = Array.from(sourceTasks);
  const destClone = Array.from(destinationTasks);
  const [removed] = sourceClone.splice(source.index, 1);

  removed.category = destination.droppableId; // Update the category
  destClone.splice(destination.index, 0, removed);

  return {
    [source.droppableId]: sourceClone,
    [destination.droppableId]: destClone,
  };
};

const TaskBoard = ({
  tasks,
  setTasks,
  developers,
  projects,
  categories,
  setSelectedTask,
  fetchTasks,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const [loadingText, setLoadingText] = useState("🤡 Loading..."); // New state for loading text

  const [newTask, setNewTask] = useState({
    title: "",
    description: "",
    developer: "none",
    project: "all",
    hours: 0,
  });

  // Fetch a funny text
  const fetchFunnyText = async () => {
    try {
      const response = await axios.get(
        "https://official-joke-api.appspot.com/random_joke"
      );
      const joke = `${response.data.setup} 😂 ${response.data.punchline}`;
      setLoadingText(joke);
    } catch (error) {
      setLoadingText("🤡 Failed to load joke, please try again later.");
    }
  };

  // Update tasks on the server
  const updateTasks = async (updatedTaskData) => {
    setTasks(updatedTaskData); // Update local state
    try {
      await axiosInstance.post("/updatetasks", updatedTaskData);
    } catch (error) {
      console.error("Error updating tasks:", error);
    }
  };

  // Handle drag and drop events
  const onDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) return;

    setIsLoading(true);
    await fetchFunnyText();

    try {
      let newState;

      if (source.droppableId === destination.droppableId) {
        const reorderedTasks = reorder(
          tasks[source.droppableId],
          source.index,
          destination.index
        );
        newState = { ...tasks, [source.droppableId]: reorderedTasks };
      } else {
        const movedResult = move(
          tasks[source.droppableId],
          tasks[destination.droppableId],
          source,
          destination
        );
        newState = { ...tasks, ...movedResult };

        if (destination.droppableId === "items3") {
          const completedTask =
            movedResult[destination.droppableId][destination.index];
          completedTask.completedAt = new Date().toISOString(); // Add completion time
          await sendTaskToTelegram(completedTask._id);
        }
      }

      await updateTasks(newState);
    } catch (error) {
      console.error("Error during drag and drop operation:", error);
    } finally {
      await fetchTasks();
      setIsLoading(false);
      setLoadingText("🤡 Loading...");
    }
  };

  // Send task to Telegram
  const sendTaskToTelegram = async (taskId) => {
    try {
      await axiosInstance.post(`/sendtask/${taskId}`);
    } catch (error) {
      console.error("Error sending task to Telegram:", error);
    }
  };

  // Handle developer change
  const handleDeveloperChange = async (taskId, developerId) => {
    const newState = { ...tasks };
    for (const key in newState) {
      newState[key] = newState[key].map((task) =>
        task.id === taskId ? { ...task, developer: developerId } : task
      );
    }
    await updateTasks(newState);
    await fetchTasks();
    setSelectedTask(null);
  };

  // Handle project change
  const handleProjectChange = async (taskId, projectId) => {
    const newState = { ...tasks };
    for (const key in newState) {
      newState[key] = newState[key].map((task) =>
        task.id === taskId ? { ...task, project: projectId } : task
      );
    }
    await updateTasks(newState);
    await fetchTasks();
  };

  // Handle hours change
  const handleHoursChange = async (taskId, hours) => {
    const newState = { ...tasks };
    for (const key in newState) {
      newState[key] = newState[key].map((task) =>
        task.id === taskId ? { ...task, hours: hours } : task
      );
    }
    await updateTasks(newState);
    await fetchTasks();
  };

  // Handle task archiving
  const handleArchiveTask = async (taskId) => {
    try {
      await axiosInstance.post(`/movetask/${taskId}`);
      await fetchTasks();
    } catch (error) {
      console.error("Error archiving task:", error);
    }
  };

  // Open edit modal
  const openEditModal = (task) => {
    setTaskToEdit(task);
    setNewTask(task);
    setIsModalOpen(true);
  };

  return (
    <div className="relative">
      {isLoading && (
        <div className="absolute inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="loader" style={{ color: "#f0f0f0" }}>
            {loadingText}
          </div>
        </div>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex flex-col md:flex-row h-full">
          {categories.map((category) => (
            <Droppable key={category.id} droppableId={category.id}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="p-4 w-full md:w-1/3 min-w-[300px] min-h-full h-screen overflow-y-auto relative border border-gray-300"
                >
                  {/* Заголовок в боксе */}
                  <div className="w-full bg-white shadow-md rounded-md mb-4 text-center py-2">
                    <h2 className="text-lg font-bold">{category.title}</h2>
                  </div>
                  <div className="space-y-2">
                    {tasks[category.id] && tasks[category.id].length > 0 ? (
                      tasks[category.id]
                        .filter((task) => task.display) // Only display filtered tasks
                        .map((item, index) => (
                          <TaskCard
                            key={item.id} // Ensure unique key
                            item={item}
                            index={index}
                            developers={developers}
                            projects={projects}
                            handleDeveloperChange={handleDeveloperChange}
                            handleProjectChange={handleProjectChange}
                            handleHoursChange={handleHoursChange}
                            handleArchiveTask={handleArchiveTask}
                            openEditModal={openEditModal}
                          />
                        ))
                    ) : (
                      <div className="text-gray-500 text-center">Нет задач</div>
                    )}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          ))}
        </div>
        {isModalOpen && (
          <AddTaskModal
            developers={developers}
            projects={projects}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            newTask={newTask}
            setNewTask={setNewTask}
            setTasks={setTasks}
            taskToEdit={taskToEdit}
            fetchTasks={fetchTasks}
          />
        )}
      </DragDropContext>
    </div>
  );
};

export default TaskBoard;
